import React, { useState, useRef, useEffect } from "react";
import "./image-modal.scss";

const ImageModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);
  const videoRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Videoyu 2. saniyeden başlatır
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div className="image-modal mt-5">
          <div className="image-modal-content" ref={modalRef}>
            <video
              ref={videoRef}
              src="/images/player/tanitim-filmi.mp4"
              autoPlay
              loop
              controls
              className="img-fluid"
            >
              <source src="/player/tanitim-filmi.mp4" type="video/mp4" />
            </video>
            
          </div>
        </div>
      )}
    </>
  );
};

export default ImageModal;
