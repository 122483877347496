import React from "react";
import PageHeader from "../common/page-header";
import { Container } from "react-bootstrap";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import BackgroundLogo from "../common/background-logo";
import "./hakkimda.scss";

const Hakkimda = () => {
  return (
    <Container>
      <div>
        <PageHeader title="ÖZGEÇMİŞ" />
        <BackgroundLogo />
        <div className="text-center mb-5">
          <img className="hakkimda" src="/images/slider/slide-1.jpg" alt="" width={700} />
        </div>
        <div className="text-center">
          <p style={{ fontSize: "20px", color: "#D7DBDD" }}>
            Merhabalar ben Op. Dr. Altay Nihat ACAR kalp ve damar cerrahi uzmanıyım. -
            1986 yılının Ekim ayında Ankara’da doğdum, memur bir ailenin çocuğu
            olarak sırasıyla Erzurum, Antakya, İzmir’de yaşadım, sonrasında
            Edirne, Çanakkale, Isparta, Antalya, Kilis ve Gaziantep’te eğitim ve
            çalışma hayatıma devam ettim. Evliyim, bir oğlum var. Yaşadığım bu
            illerin demografik yapıları, farklı iklimleri ve kültürlerinin
            benliğimin gelişiminde muhakkak katkısı olmuş olsa da aslen
            özellikle ilkokul, ortaokul ve lise yıllarımın geçtiği İzmir’in
            kültürel yapısının karakterimi oluşturduğu düşüncesindeyim. Çocukluk
            yıllarımda ilk duyduğum ve öğrendiğim ünlü sözlerden biri Buddha’ya
            aitti “Sağlık en büyük armağan, memnuniyet en büyük zenginlik,
            sadakat en iyi ilişkidir.” Bu sözün ışığında bir cerrah olabilmek ve
            klasik cerrahiler yerine hastaların hızlıca günlük hayatına
            dönebileceği tedavileri geliştirmek uygulamak için eğitim hayatıma
            başladım ve hala aynı düstur ile mesleğimi Liv Hospital Gaziantep’te
            son teknoloji donanım ve en güncel tedavileri uygulayarak ifa
            etmekteyim.
          </p>
        </div>

        {/* EĞİTİM BİLGİLERİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Eğitim Bilgileri <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            Lisans- Y. Lisans TIP FAKÜLTESİ TRAKYA ÜNİVERSİTESİ 2005-2012 <br />
            <br />
            Doktora/S.Yeterlik/ Tıpta Uzmanlık KALP VE DAMAR CERRAHİSİ SÜLEYMAN
            DEMİREL ÜNİVERSİTESİ 2014-2019
          </p>
        </div>

        {/* İŞ DENEYİMİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> İş Deneyimi <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            Tabip SAĞLIK BAKANLIĞI-ÇANAKKALE AYVACIK DEVLET HASTANESİ 2012-2013{" "}
            <br />
            <br />
            Arş.Gör. SÜLEYMAN DEMİREL ÜNİVERSİTESİ TIP FAKÜLTESİ- KALP VE DAMAR
            CERRAHİSİ ANABİLİM DALI 2014-2019 <br />
            <br />
            Uzman Tabip (Kalp ve Damar Cerrahisi) KİLİS DEVLET HASTANESİ
            2020-2022 <br />
            <br />
            Uzman Tabip (Kalp ve Damar Cerrahisi) LIV HOSPITAL GAZİANTEP
            2020-...
          </p>
        </div>

        {/* SEMİNERLER VE KURSLAR */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Sertifika ve Kurs <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            1. “Psikodrama Yöntemiyle Hasta Hekim İlişkisine Bakış Kursu” -
            Nisan 2009, Denizli, Pamukkale Üniversitesi Tıp Fakültesi <br />
            <br />
            2. “Temel Yoğun Bakım Kursu” – Mayıs 2011, Edirne, Dahili ve Cerrahi
            Bilimler Yoğun Bakım Derneği <br />
            <br />
            3. “Tıbbi problemlerin çözümünde fizyoloji bilgisini kullanmak: Asit
            baz dengesi Çalıştayı” – Haziran 2012, Edirne, Maastricht
            Üniversitesi ve Trakya Üniversitesi Tıp Fakültesi Fizyoloji Anabilim
            Dalları <br />
            <br />
            4. “Diyabet Akran Eğitim Programı” – Ocak 2013, Çanakkale, Türkiye
            Cumhuriyeti Cumhurbaşkanlığı Himayelerinde Türkiye Diyabet Vakfı –
            Ayvacık Devlet Hastanesi <br />
            <br />
            5. “Masterclass 2017:AAA-open surgery: Bifurcated graft” – Kasım
            2017, Kıbrıs, 18. Ulusal Vasküler ve Endovasküler Cerrahi Derneği –
            Vascular International & Getinge <br />
            <br />
            6. “Kolorektal Cerrahi Hastalarında Nütrisyon Okulu-4” – Mayıs 2018,
            Antalya, Türk Kolon ve Rektum Cerrahisi Derneği
          </p>
        </div>

        {/* YURTİÇİ BİLDİRİLERİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Uluslararası Hakemli Dergilerde Yayımlanan
            Makaleler <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1. Yavuz T, Acar AN, Aydın H, Ekingen E. A retrospective study of a
            new n-butyl-2-cyanoacrylate glue ablation catheter incorporated with
            application guiding light for the treatment of venous insufficiency:
            Twelve-month results. Vascular. 2018 Oct;26(5):547-555. doi:
            10.1177/1708538118770548. Epub 2018 Apr 11. PMID: 29642798. <br />
            <br />
            2. Ontas H, Yavuz T, Acar AN, Uysal D. Comparison of ultrasound
            results following endovenous laser ablation and radiofrequency
            ablation in the treatment of varicose veins. Ann Ital Chir.
            2019;90:457-462. PMID: 31661439. <br />
            <br />
            3. Yavuz T, Acar AN, Yavuz K, Ekingen E. A Retrospective Study of
            Deep Vein Insufficiency Treatment Device: ICT. Ann Vasc Dis. 2020
            Sep 25;13(3):255-260. doi: 10.3400/avd.oa.20-00016. PMID: 33384727;
            PMCID: PMC7751084.
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Ulusal Hakemli Dergilerde Yayımlanan
            Makaleler <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1. Ceviker K, Gublu M, Acar AN, Yavuz T, Uysal D, Koyuncuoglu H.
            Drop Foot; A Case of An Unexpected Complication After Coronary
            Artery Bypass Surgery. SmyrnaMed Case.2017;1:13-17. <br />
            <br />
            2. Ontas H, Serhatlioglu F, Gozuacik Ruzgar AA, Acar AN. Comparison
            of Primary Suturing and Dacron Patch in Carotid Endarterectomy.
            Pamukkale Medical Journal.2023;16(2):180-186
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Uluslararası Kongrelerde Sunulan
            Bildiriler
            <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1. Acar AN, Ontas H, Yavuz T. Short-term Results of the Rotational
            Atherectomy in Varying Length of Peripheric Arterial Occlusive
            Diseases, 4th International Health Sciences and Life Congress
            Burdur/TURKEY 08-10 Nisan 2021 <br />
            <br />
            2. Ontas H, Acar AN. Our Clinical Experience in the Medical
            Treatment of Deep Vein Thrombosis. 17th International Congress of
            Update in Cardiology and Cardiovascular Surgery. Online Kongre 05-07
            Kasım 2021 <br />
            <br />
            3. Acar AN. Phlegmasia Cerulea Dolens on COVID-19 Patient. Union of
            Thrace Universities 4th International Health Sciences Congress
            Kırklareli/TURKEY. 11-12 Kasım 2021
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Ulusal Kongrelerde Sunulan Bildiriler{" "}
            <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1. Uysal D, Acar AN, Cetin H, Akgun H, Yavuz T. Yüksek bir mortalite
            nedeni: Geç gelen akut arteriyel oklüzyon. 15. Türk Kalp ve Damar
            Cerrahisi Kongresi. Belek, Antalya 26-29 Ekim 2018 S:38. <br />
            <br />
            2. Acar AN. Periferik Arter Hastalığında Balon Anjiyoplasti Sonrası
            Gözlenen Trombüsün Tirofiban ile Selektif Tedavisi. Kalp ve Damar
            Cerrahisinde “BildiriYorum” Karma Toplantısı. The Marmara Taksim
            Hotel, İstanbul 19 Haziran 2021. <br />
            <br />
            3. Acar AN, Ontas H. Yüksek Enerjili Travma ve Ateşli Silah
            Yaralanmalarında Kardiyovasküler Cerrahi Deneyimlerimiz. 20. Ulusal
            Vasküler ve Endovasküler Cerrahi Kongresi, 11. Ulusal Fleboloji
            Kongresi. Antalya 28-31 Ekim 2021
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Kitap ve Bölüm Yazarlığı{" "}
            <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1. Ontas H, Acar AN. Cerrahi Branşlarda Görülen Nadir Vakalar, Bölüm
            13: “Akut Apandisit Kliniğiyle Acil Servise Başvuran İliofemoral
            Derin Ven Trombozu Olgusu” 2020, Bölüm Yazarı, Akademisyen Kitabevi,
            ISBN:9786257275217 <br />
            <br />
            2. Acar AN, Ontas H. Cerrahi Branşlarda Görülen Nadir Vakalar, Bölüm
            14: “Hıçkırık ve Sol Üst Ekstremite İskemisi Gözlenen Kalıcı Tünelli
            Hemodiyaliz Kateteri Komplikasyonu Olgusu” 2020, Bölüm Yazarı,
            Akademisyen Kitabevi, ISBN:9786257275217 <br />
            <br />
            3. Atay M, Acar AN. Venöz Tromboembolide Güncelleme, Bölüm 19 “Vena
            Kavaya İnfiltre Tümörler ve Tümör Trombüsleri” 2021, Bölüm Yazarı,
            İstanbul Tıp Kitabevleri, ISBN:9786257291644 <br />
            <br />
            4. Acar AN, Eskiocak A. ECMO 360⁰ “Ekstrakorporal CO2
            Uzaklaştırılması (ECCO2R)” 2021, Bölüm Yazarı, Akademisyen Kitabevi,
            ISBN:9786258037142 <br />
            <br />
            5. Acar AN, Sen Acar A, Periferik Atlas 2022, 2.4.f. Mezenter Arter,
            Bölüm Yazarı, Akademisyen Kitabevi, ISBN:978-625-8299-00-7 <br />
            <br />
            6. Acar AN. Periferik Atlas 2022, Bölüm 2.3.c.1. Arter
            Patolojilerinin Endovasküler Tedavi Seçenekleri (Karotis Arter),
            Bölüm Yazarı, Akademisyen Kitabevi, ISBN: 978-625-8299-00-7
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Hakkimda;
