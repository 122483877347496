import React from "react";
import "./welcome.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaUserDoctor } from "react-icons/fa6";
import { BiSolidLike } from "react-icons/bi";
import { MdTaskAlt } from "react-icons/md";

const Welcome = () => {
  return (
    <div className="welcome">
      <Container>
        <Row className="g-5 align-items-center">
          <Col   md={6}>
            <Image src="/images/hoca/klinik.jpg" className="resim"/>
          </Col>
          <Col md={6}>
            <h2>Güvenilir ve Modern Cerrahi Hizmetler</h2>
            <p>
              Hastalarımızın sağlığını ve yaşam kalitesini ön planda tutarak,
              cerrahi alanında yenilikçi ve uzman bir yaklaşımla hizmet
              sunmaktayız. Uzmanlık alanlarımızda modern tıp tekniklerini ve
              etik değerleri birleştirerek hastalarımıza güven veren, etkili
              tedavi süreçleri sunuyoruz.
            </p>
            <div className="d-flex">
              <FaUserDoctor style={{ fontSize: "80px", marginRight: "15px" }} />
              <div>
                <h5> Hedefimiz</h5>
                <p>
                  Hedefimiz, cerrahi alanında yüksek başarı oranlarıyla
                  hastalarımızın sağlık ihtiyaçlarına hızlı ve etkili çözümler
                  sunmaktır. Her bireye özel yaklaşımlarımızla, hasta
                  memnuniyetini en üst düzeye çıkarmayı ve yaşam kalitesini
                  artırmayı amaçlıyoruz.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <BiSolidLike style={{ fontSize: "80px", marginRight: "15px" }} />
              <div>
                <h5>Misyonumuz</h5>
                <p>
                  Misyonumuz, alanımızda lider ve güvenilir bir sağlık hizmeti
                  sunucusu olmaktır. Modern cerrahi tekniklerle, hastalarımızın
                  tedavi süreçlerini kolaylaştırarak, onların sağlığını ve
                  mutluluğunu önceliğimiz haline getiriyoruz.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <MdTaskAlt style={{ fontSize: "80px", marginRight: "15px" }} />
              <div>
                <h5>Vizyonumuz</h5>
                <p>
                  Vizyonumuz, yenilikçi tedavi yöntemleri ve etik değerlere
                  bağlı ilkeli yaklaşımımızla ülkemizin en saygın sağlık
                  merkezlerinden biri olmaktır. Laparoskopik (Kapalı) cerrahi
                  başta olmak üzere modern cerrahi uygulamalarımızla, hasta
                  memnuniyetini ve güvenini esas alan bir anlayışla hizmet
                  vermeye devam ediyoruz.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Welcome;
