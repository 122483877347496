export const config = {
  project: {
    name: "Altay Nihat ACAR",
    slogan:
      "Sağlığınıza Özel Cerrahi Çözümler",
    description:
      " Sağlığınızın her aşamasında yanınızda olan ekibimiz, kişiye özel cerrahi çözümlerle hizmet vermektedir.",
    version: "1.0.0",
  },
  contact: {
    phone1: "0531 232 50 00",
    phone2: "444 4 548",
    email: "altaynihatacar@hotmail.com",
    address: "15 Temmuz Mahallesi Alparslan Türkeş Caddesi No:66/B İç Kapı No:2 ŞEHİTKAMİL/GAZİANTEP Primemall Avm Karşısı Sarı Villalar",
    mapURL: "https://maps.app.goo.gl/wHhRhG3iu6EUt4a37",
    mapEmbedURL:
    "https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d101894.0556684526!2d37.23892401376029!3d37.05297331177892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x1531e51939edbe89%3A0xe971287aebe89dd1!2zMTUgVGVtbXV6LCBBbHRheSBOaWhhdCBBY2FyLCBBbHBhcnNsYW4gVMO8cmtlxZ8gQ2QgacOna2FwxLEgbm86MiwgxZ5laGl0a2FtaWwvR2F6aWFudGVw!3m2!1d37.0528648!2d37.3213256!4m5!1s0x1531e51939edbe89%3A0xe971287aebe89dd1!2zMTUgVGVtbXV6LCBBbHBhcnNsYW4gVMO8cmtlxZ8gQ2QgTm86NjYvYiBpw6drYXDEsSBubzoyLCAyNzAwMCDFnmVoaXRrYW1pbC9HYXppYW50ZXA!3m2!1d37.0528648!2d37.3213256!5e0!3m2!1str!2str!4v1735772588936!5m2!1str!2str",
    socialMedia: {
      twitter: "https://twitter.com",
      facebook: "https://facebook.com",
      instagram: "https://www.instagram.com/op.dr.altay.nihat.acar/",
      youtube: "https://youtube.com",
    },
  },
  api: {
    baseUrl: "https://altaynihatacar.com/api",
  },
  pageRoles: {
    dashboard: ["ADMİN"]
  }
};




