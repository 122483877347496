// import React, { useEffect, useState } from "react";
// import "./gallery.scss";
// import PageHeader from "../common/page-header";
// import { FaArrowAltCircleRight } from "react-icons/fa";
// import BackgroundLogo from "../common/background-logo";
// import axios from "axios";
// import { Col, Container, Row } from "react-bootstrap";
// import Youtube from "./youtube";
// import { Link } from "react-router-dom";
// import { config } from "../../helpers/config";


// const API_URL = config.api.baseUrl;

// const Videolar = () => {

//   const [youtubeLinkList, setyoutubeLinkList] = useState([]);
//   useEffect(() => {
//     getVideos();
//   }, []);

//   function getVideos() {
//     axios.get(`${API_URL}/?type=videos`).then(function (response) {
//       console.log(response.data);
//       setyoutubeLinkList(response.data);
//     });
//   }


//   return (
//     <div>
//       <PageHeader title="VİDEOLAR" />
//       <BackgroundLogo/>
//       <div className='text-center'>
      
//       <Link to="/galeri">GALERİ</Link>  <span className="mx-2"> <FaArrowAltCircleRight /></span><Link to="/photos">FOTOĞRAFLAR</Link>
//       </div>
//       {/* YouTube videosunu gömün */}
//       <div className="text-center" >
//      <Container>
//      <Row>
//           {youtubeLinkList.map((item) => (
//               <Col key={item.id} className='d-flex mt-2' xs={12} lg={6}>
//                {/*  <Youtube youtubeVideoLink={item.url}/> */}

//               </Col>
//             ))}
//           </Row>
//      </Container>
       
//       </div>
//     </div>
//   );
// };

// // YouTube video linkinden embed kodunu almak için yardımcı fonksiyon
// /* const getYouTubeEmbedCode = (videoLink) => {
//   const videoId = videoLink.split("v=")[1];
//   return `<iframe width="460" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
// }; */

// export default Videolar;


import React, { useEffect, useState } from "react";
import "./gallery.scss";
import PageHeader from "../common/page-header";
import { FaArrowAltCircleRight } from "react-icons/fa";
import BackgroundLogo from "../common/background-logo";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../helpers/config";

const API_URL = config.api.baseUrl;

const Videolar = () => {
  const [youtubeLinkList, setyoutubeLinkList] = useState([]);

  useEffect(() => {
    getVideos();
  }, []);

  function getVideos() {
    axios.get(`${API_URL}/?type=videos`).then(function (response) {
      console.log(response.data);
      setyoutubeLinkList(response.data);
    });
  }

  return (
    <div>
      <PageHeader title="VİDEO YAYINLARIM" />
      <BackgroundLogo />
      <div className='text-center'>
        <Link to="/galeri">GALERİ</Link>  <span className="mx-2"> <FaArrowAltCircleRight /></span><Link to="/photos">FOTOĞRAFLAR</Link>
      </div>
      {/* Videolar */}
      <div className="text-center">
        <Container>
          <Row>
            {/* YouTube videoları */}
            {youtubeLinkList.map((item) => (
              <Col key={item.id} className='d-flex mt-2' xs={12} lg={6}>
                <iframe
                  width="460"
                  height="315"
                  src={`https://www.youtube.com/embed/${item.url.split("v=")[1]}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`Video ${item.id}`}
                ></iframe>
              </Col>
            ))}

            {/* Public/player dizinindeki video */}


            <Col className='d-flex mt-2 justify-content-center' xs={12} lg={6}>
              <video width="460" height="315" controls>
                <source src="/images/player/toplardamar-tikanikligi.mp4" type="video/mp4" />
                Tarayıcınız video oynatmayı desteklemiyor.
              </video>
            </Col>
            <Col className='d-flex mt-2 justify-content-center' xs={12} lg={6}>
              <video width="460" height="315" controls>
                <source src="/images/player/lipodem.mp4" type="video/mp4" />
                Tarayıcınız video oynatmayı desteklemiyor.
              </video>
            </Col>
            <Col className='d-flex mt-2 justify-content-center' xs={12} lg={6}>
              <video width="460" height="315" controls>
                <source src="/images/player/varis.mp4" type="video/mp4" />
                Tarayıcınız video oynatmayı desteklemiyor.
              </video>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Videolar;